import React from "react";
import { useTranslation } from "react-i18next";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { format } from "date-fns";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function TrialPayamentView({ trial, levelPackage }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <aside className="payment__plan">
        <div className="payment__plan__detail">
          <h2 className="payment__plan__detail__title">
            {t("masseur.viewPayement.trialTitle")}
          </h2>
          <p className="payment__plan__detail__paragraph">
            {t("masseur.viewPayement.expirePackage", {
              date: format(trial.expire_date, "MMMM do, yyyy"),
            })}{" "}
            {}
          </p>
        </div>
        <div className="payment__plan__container">
          <h2 className="payment__plan__title">
            {t("masseur.viewPayement.free")}
          </h2>
        </div>
      </aside>
      <aside className="plan__list">
        <TaskAltIcon sx={{ color: "#0AB361 " }} />
        <h4>
          {t("masseur.myLevel.uploadImages", {
            images_limit: levelPackage.images_limit,
          })}
        </h4>
      </aside>
      <aside className="plan__list">
        <TaskAltIcon sx={{ color: "#0AB361 " }} />
        <h4>
          {t("masseur.myLevel.uploadVideos", {
            videos_limit: levelPackage.video_count,
          })}
        </h4>
      </aside>
      <aside className="plan__list">
        <TaskAltIcon sx={{ color: "#0AB361" }} />
        <h4>
          {t("masseur.myLevel.travelAd", {
            travel_ad: levelPackage.travel_ad,
          })}
        </h4>
      </aside>
      <aside className="plan__list">
        <TaskAltIcon sx={{ color: "#0AB361 " }} />
        <h4>
          {t("masseur.myLevel.localAd", {
            local_ad: levelPackage.local_ad,
          })}
        </h4>
      </aside>
      <aside className="plan__list">
        <TaskAltIcon sx={{ color: "#0AB361" }} />
        <h4>
          {t("masseur.myLevel.serviceHourSchedule", {
            service_hour_schedule: levelPackage.service_hour_schedule,
          })}
        </h4>
      </aside>
      <aside className="plan__list">
        <TaskAltIcon sx={{ color: "#0AB361" }} />
        <h4>
          {t("masseur.myLevel.serviceAndMassageType", {
            service_and_massage_types: levelPackage.service_and_massage_types,
          })}
        </h4>
      </aside>
      <aside className="plan__list">
        <TaskAltIcon sx={{ color: "#0AB361" }} />
        <h4>
          {t("masseur.myLevel.serviceAndMassageType", {
            service_and_massage_types: levelPackage.service_and_massage_types,
          })}
        </h4>
      </aside>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          className="updateButon"
          onClick={() => navigate("/masseur/level")}
        >
          Upgrade plan
        </Button>
      </div>
    </>
  );
}
