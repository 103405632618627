import { SERVER_API } from "../helpers/variable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "../context/ToastContext";
import { store } from "../redux/store";
import {
  clientLoginSuccess,
  clientLogoutSuccess,
} from "../redux/slices/auth/client";

export default function ClientAuth() {
  const { showToast } = useToast();
  const navigate = useNavigate();

  async function clientSignup(client) {
    await axios
      .post(`${SERVER_API}/clients/sign_up`, { client: { ...client } })
      .then((response) => {
        if (response.status == 200) {
          navigate("/signin");
          showToast({
            message: "Please check your email and confirm it!",
            flag: "success",
          });
        } else {
          showToast({
            message: response.data.message,
            flag: "error",
          });
        }
      })
      .catch((error) => {
        showToast({ message: error.message, flag: "error" });
      });
  }

  async function clientSignin(client) {
    await axios
      .post(`${SERVER_API}/clients/login`, {
        client: { ...client },
      })
      .then((response) => {
        if (
          response.data.active &&
          response.data.account_verified &&
          response.data.initial_profile_setup == "completed"
        ) {
          showToast({ message: "Succesfully SignedIn!", flag: "success" });
          store.dispatch(
            clientLoginSuccess({
              client: response.data,
              token: response.headers.authorization,
            })
          );
          return navigate("/");
        } else if (response.data.initial_profile_setup !== "completed") {
          showToast({ message: "Succesfully SignedIn!", flag: "success" });
          store.dispatch(
            clientLoginSuccess({
              client: response.data,
              token: response.headers.authorization,
            })
          );
          navigate(`/client/profileSetup/${response.data.setup_stage}`);
        } else {
          showToast({
            message:
              "Your account has some issue, Please contact to administrator ",
            flag: "error",
          });
        }
      })
      .catch((error) => {
        showToast({ message: error.message, flag: "error" });
      });
  }

  return {
    clientSignin,
    clientSignup,
  };
}
