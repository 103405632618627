import { SERVER_API } from "../helpers/variable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "../context/ToastContext";
import { store } from "../redux/store";
import {
  masseurLoginSuccess,
  masseurLogoutSuccess,
} from "../redux/slices/auth/masseur";

export default function MasseurAuth() {
  const { showToast } = useToast();
  const navigate = useNavigate();

  async function masseurSignup(masseur) {
    await axios
      .post(`${SERVER_API}/masseurs/sign_up`, { masseur: { ...masseur } })
      .then((response) => {
        if (response.status == 200) {
          navigate("/signin");
          showToast({
            message: "Please check your email and confirm it!",
            flag: "success",
          });
        } else {
          showToast({
            message: response.data.message,
            flag: "error",
          });
        }
      })
      .catch((error) => {
        showToast({ message: error.message, flag: "error" });
      });
  }

  async function masseurSignin(masseur) {
    await axios
      .post(`${SERVER_API}/masseurs/login`, { masseur: { ...masseur } })
      .then((response) => {
        debugger;
        if (
          response.data.active &&
          response.data.account_verified &&
          response.data.initial_profile_setup == "completed"
        ) {
          showToast({ message: "Succesfully SignedIn!", flag: "success" });
          store.dispatch(
            masseurLoginSuccess({
              masseur: response.data,
              token: response.headers.authorization,
            })
          );
          return navigate("/masseur/profile");
        } else if (response.data.initial_profile_setup !== "completed") {
          showToast({ message: "Succesfully SignedIn!", flag: "success" });
          store.dispatch(
            masseurLoginSuccess({
              masseur: response.data,
              token: response.headers.authorization,
            })
          );
          navigate(`/masseur/profileSetup/${response.data.setup_stage}`);
        } else {
          showToast({
            message:
              "Your account has some issue, Please contact to administrator ",
            flag: "error",
          });
        }
      })
      .catch((error) => {
        debugger;
        showToast({ message: error.message, flag: "error" });
      });
  }

  return {
    masseurSignin,
    masseurSignup,
  };
}
