import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  LocalPhone as LocalPhoneIcon,
  FacebookOutlined as FacebookOutlinedIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";

import { Button } from "@mui/material";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer__top">
        <div className="footer__top__phone">
          <p>{t("client.footer.phoneNumber")}</p>
          <div className="footer__top__phone__number">
            <LocalPhoneIcon className="footer__top__phone__number__icon" />
            +1 773 931-1500
          </div>
        </div>
        {/* <div className="footer__top__social">
          <p>{t("client.footer.socialAccount")}</p>
          <div className="footer__top__social__icons">
            <FacebookOutlinedIcon className="footer__top__social__icons__icon" />
            <TwitterIcon className="footer__top__social__icons__icon" />
            <LinkedInIcon className="footer__top__social__icons__icon" />
            <YouTubeIcon className="footer__top__social__icons__icon" />
            <InstagramIcon className="footer__top__social__icons__icon" />
          </div>
        </div> */}
        {/* <div className="footer__top__subscription">
          <input type="email" placeholder={t("client.footer.enterYourEmail")} />
          <Button variant="contained">{t("client.footer.subscribe")}</Button>
        </div> */}
      </div>
      <div className="footer__body">
        <div className="footer__body__left">
          <p>T{t("client.footer.topGay")}</p>

          <div className="footer__body__left__citiesBox">
            <div className="footer__body__left__citiesBox__cities">
              <span>{t("client.footer.london")}</span>
              <span>{t("client.footer.newYork")}</span>
              <span>{t("client.footer.losAngeles")}</span>
              <span>{t("client.footer.sanDiego")}</span>
              <span>{t("client.footer.chicago")}</span>
              <span>{t("client.footer.lasVegas")}</span>
              <span>{t("client.footer.dallas")}</span>
              <span>{t("client.footer.miami")}</span>
            </div>
            <div className="footer__body__left__citiesBox__cities">
              <span>{t("client.footer.phoenix")}</span>
              <span>{t("client.footer.tempa")}</span>
              <span>{t("client.footer.atlanta")}</span>
              <span>{t("client.footer.westHollywood")}</span>
              <span>{t("client.footer.tucson")}</span>
              <span>{t("client.footer.houston")}</span>
              <span>{t("client.footer.barcelona")}</span>
              <span>{t("client.footer.oriando")}</span>
            </div>
            <div className="footer__body__left__citiesBox__cities">
              <span>{t("client.footer.riverside")}</span>
              <span>{t("client.footer.madison")}</span>
              <span>{t("client.footer.newOrieans")}</span>
              <span>{t("client.footer.providence")}</span>
              <span>{t("client.footer.denver")}</span>
              <span>{t("client.footer.melbourne")}</span>
              <span>{t("client.footer.fortLauderdale")}</span>
              <span>{t("client.footer.toronto")}</span>
            </div>
            <div className="footer__body__left__citiesBox__cities">
              <span>{t("client.footer.mesa")}</span>
              <span>{t("client.footer.johnston")}</span>
              <span>{t("client.footer.hollywood")}</span>
              <span>{t("client.footer.costaMesa")}</span>
              <span>{t("client.footer.orange")}</span>
              <span>{t("client.footer.stLouisPark")}</span>
              <span>{t("client.footer.saintPetersburg")}</span>
              <span>{t("client.footer.schaumburg")}</span>
            </div>
          </div>
        </div>
        <div className="footer__body__right">
          <div className="footer__body__right__box">
            <div className="footer__body__right__box__aboutUs">
              <p>{t("client.footer.aboutUs")}</p>
              <div className="footer__body__right__box__aboutUs__options">
                <span>{t("client.footer.advertise")}</span>
                <span>{t("client.footer.philosophy")}</span>
                <span>{t("client.footer.gayMasseursmap")}</span>
              </div>
            </div>
            <div className="footer__body__right__box__aboutMassage">
              <p>{t("client.footer.aboutMassage")}</p>
              <div className="footer__body__right__box__aboutMassage__options">
                <span>{t("client.footer.therapeuticMassage")}</span>
                <span>{t("client.footer.sensualMassage")}</span>
              </div>
            </div>
          </div>
          <div className="footer__body__right__policy">
            <Link to="/privacy-policy">{t("client.footer.policy")}</Link> /{" "}
            <Link to="/term-&-conditions">{t("client.footer.terms")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
