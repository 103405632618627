import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import GreenInput from "../../../../../components/Form/GreenInput";
import GreenSelect from "../../../../../components/Form/GreenSelect";
import ProfileApis from "../../../../../apis/masseur/ProfileApis";
import Loading from "../../../../../components/general/Loading";
import { useTranslation } from "react-i18next";
import BlueButton from "../../../../../components/Form/BlueButton";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  width: 440,
};

export default function EditPersonaliation({ profile, setProfile }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { isProfileLoading, getPersonalization, updatePersonalization } =
    ProfileApis();
  const [personalization, setPersonalization] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleOpen = () => {
    setOpen(true);
    fetchPersonalizations();
    if (errorMessage) return null;
  };
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const fetchPersonalizations = async () => {
    const response = await getPersonalization();
    if (response.data) {
      setPersonalization(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const personalization = {
      weight_unit: profile.weight_unit,
      weight: profile.weight,
      height_unit: profile.height_unit,
      height: profile.height,
      hair_color_id: profile.hair_color_id,
      eye_color_id: profile.eye_color_id,
      body_hair_id: profile.body_hair_id,
      build_id: profile.build_id,
    };
    const response = await updatePersonalization(personalization);
    if (response.data) {
      handleClose();
      setProfile(response.data);
    }
    setLoading(false);
  };

  return (
    <main>
      <header
        className="masseur__profilesetting__section__box__container"
        onClick={handleOpen}
      >
        <h4 className="masseur__profilesetting__section__box__container__title">
          {t("masseur.myProfile.editPersonalization")}
        </h4>
        <div className="masseur__profilesetting__section__box__container__icon">
          <ArrowForwardIosIcon fontSize="small" />
        </div>
      </header>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
          WebkitBackdropFilter: "blur(15px)",
        }}
      >
        <Box sx={style} className="profilesection">
          {isProfileLoading || personalization == null ? (
            <Loading />
          ) : (
            <div>
              <section className="modal__container">
                <div className="modal__container__icon" onClick={handleClose}>
                  <ArrowBackIosIcon />
                </div>
                <h4 className="passwordChange__container__passChange">
                  {t("masseur.myProfile.editPersonalization")}
                </h4>
              </section>
              <section className="profileSetup__container__body__left__inputBox">
                <div>
                  <GreenSelect
                    name="weight_unit"
                    value={profile.weight_unit || null}
                    label={t("masseur.personalozationModal.weigthUnit")}
                    options={
                      [
                        { label: "Metric (KILOS)", value: "kilos" },
                        { label: "US (POUNDS)", value: "lbs" },
                      ] || []
                    }
                    handleChange={handleChange}
                  />
                </div>
                <div>
                  <GreenInput
                    label={t("masseur.personalozationModal.weigth")}
                    type="number"
                    name="weight"
                    value={profile.weight || null}
                    handleChange={handleChange}
                  />
                </div>
              </section>
              <section className="profileSetup__container__body__left__inputBox">
                <div>
                  <GreenSelect
                    name="height_unit"
                    value={profile.height_unit || null}
                    label={t("masseur.personalozationModal.heigthUnit")}
                    options={
                      [
                        { label: "METRIC (CM)", value: "cm" },
                        { label: "US (FEET)", value: "ft" },
                      ] || []
                    }
                    handleChange={handleChange}
                  />
                </div>
                <div>
                  <GreenInput
                    label={t("masseur.personalozationModal.heigth")}
                    type="number"
                    name="height"
                    value={profile.height || null}
                    handleChange={handleChange}
                  />
                </div>
              </section>

              <GreenSelect
                name="eye_color_id"
                value={profile.eye_color_id}
                label={t("masseur.personalozationModal.eyeColor")}
                options={personalization.eye_colors || []}
                handleChange={handleChange}
              />
              <GreenSelect
                name="hair_color_id"
                value={profile.hair_color_id}
                label={t("masseur.personalozationModal.hairColor")}
                options={personalization.hair_colors || []}
                handleChange={handleChange}
              />
              <GreenSelect
                name="body_hair_id"
                value={profile.body_hair_id}
                label={t("masseur.personalozationModal.bodyHair")}
                options={personalization.body_hairs || []}
                handleChange={handleChange}
              />
              <GreenSelect
                name="build_id"
                value={profile.build_id}
                label={t("masseur.personalozationModal.build")}
                options={personalization.builds || []}
                handleChange={handleChange}
              />
              <BlueButton
                variant="contained"
                className="passwordChange__container__saveButton"
                onClick={handleSubmit}
                loading={loading}
                title={t("masseur.myProfile.saveChanges")}
              />
            </div>
          )}
        </Box>
      </Modal>
    </main>
  );
}
